import * as React from 'react';
import { useState, useEffect } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import EditSecret from './editsecret';
import { useParams } from 'react-router';
import ChangeLogs from './changelogs';
import AccessLogs from './accesslogs';
import Typography from '@mui/material/Typography';
import api from '../../services/api';
import { useSnackbar } from 'notistack';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {/* {value === index && ( // Disable Lady Load */}
            {(index === 0 || value === index) && ( // Always render tab 0 (secret)
                <Box sx={{ p: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ViewSecret(props) {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(true);

    const permissionDict = {entity: '', type: 'user', read: true, write: true, share: true}
    const [secretData, setSecretData] = useState({name: '', description: '', secret: '', rotation: ''});
    const [secretMetadata, setSecretMetadata] = useState({});
    const [shareData, setShareData] = useState([{...permissionDict}]);
    const [permissions, setPermissions] = useState([{}]);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if(secretData.name == '') { // Avoid load secret again
            api
                .get(`/secret/${id}`)
                .then((response) => {
                    setSecretData({...response.data.secretData});                    
                    setSecretMetadata({...response.data.secretMetadata});
                    setShareData([...response.data.shareData]);                    
                    setPermissions({...response.data.permissions});
                    setLoading(false);
                })
                .catch((err) => {
                    enqueueSnackbar('Error while trying to fetch secret', { variant: 'error' });
                    console.log(err);
                });
        }
    }, []);

    return (
        <div className="ViewSecret">
            <Typography variant="h6">View Secret</Typography>
            <hr/>
            <Box                
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
                bgcolor="#FFF"
                style={{marginTop: "25px", width: 1200, border: '1px solid #ededed', borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 0px'}}
            >
                
                {isLoading && 'Loading...' || (
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} TabIndicatorProps={{style: {background:'rgb(244 101 150)'}}}>
                            <Tab label="Secret" {...a11yProps(0)} />
                            <Tab label="Change Logs" {...a11yProps(1)} />
                            <Tab label="Access Logs" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <EditSecret 
                            id={id} 
                            secretData={secretData}
                            secretMetadata={secretMetadata}
                            setSecretData={setSecretData}
                            shareData={shareData} 
                            setShareData={setShareData}
                            permissions={permissions}
                            permissionDict={permissionDict}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ChangeLogs secretData={secretData} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <AccessLogs />
                    </TabPanel>
                </Box>
                )}
            </Box>
        </div>
        
    );
}