import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 500,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll'
  };

  export default function ChangeLogsModal(props) {
      const [open, setOpen] = React.useState(false);
      const handleOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);
      const [visibility, setVisibility] = React.useState(false);
      const changeVisibility = () => setVisibility(!visibility);
      const [details, setDetails] = React.useState();

      useEffect(() => {
        if(open) {
            const details = JSON.stringify(JSON.parse(props.changes), null, 2);
            setDetails(details);
        }
      },[open]);

      return (
        <div className="ChangeLogsModal">
            <Tooltip title="View details" placement="top">
                <IconButton onClick={handleOpen}>
                    <ManageSearchIcon/>
                </IconButton>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        View Details
                    </Typography>
                    <Typography id="modal-modal-description" component={'span'} sx={{ mt: 2 }}>
                        {details ? 
                            <pre>
                                {details}
                            </pre> : 
                            'Loading...'
                        }
                    </Typography>                    
                </Box>
            </Modal>
        </div>
      );
  }