import * as React from 'react';
import { useState, useEffect } from "react";
import { Select, MenuItem, Autocomplete } from '@mui/material';
import { Box } from "@mui/material"
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { Checkbox } from '@mui/material';
import { Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../../services/api';

export default function SecretSharedWith(props) {
    const {shareData, setShareData, permissionDict, permissions} = props;
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);

    const setEntityValue = (index, newValue) => {
        shareData[index]["entity"] = newValue;
        setShareData([...shareData]);
    }
    
    const handleShareChangeCheckbox = (e) => {
        let index = e.target.closest('tr').dataset.index;
        let name = e.target.name.replace("[]","");
        shareData[index][name] = !shareData[index][name];
        if(name == 'read' && shareData[index][name] == false) {
            shareData[index]['write'] = false;
            shareData[index]['share'] = false;            
        }else if(name == 'write') {
            shareData[index]['share'] = false;
        }
        shareData[index]['writeBlock'] = !shareData[index]['read'];            
        shareData[index]['shareBlock'] = !shareData[index]['write'];
        setShareData([...shareData]);
    };    

    const handleShareChangeType = (e) => {
        let data = e.target.name.split('-');
        let index = data[1]
        shareData[index]["type"] = e.target.value;
        setShareData([...shareData]);
    }
    
    const handleDeleteShare = (e) => {
        if(shareData.length === 1) return window.alert('You need at least one shared information');
        let index = e.target.closest('tr').dataset.index;
        shareData.splice(index, 1);
        setShareData([...shareData]);
    }; 

    useEffect(() => {
        if(props.edit !== true) {
            const userID = JSON.parse(sessionStorage.getItem("loginSession")).nameID;
            shareData[0].entity = userID;
            setShareData([...shareData]);
        }
        api.get(`/users`).then((response) => { setUsers(response.data); });
        api.get(`/groups`).then((response) => { setGroups(response.data); });
            
    }, []);

    return (
        <div className="SecretSharedWith" style={{border: '1px solid #ededed', boxShadow: '1px 1px 1px 0px rgba(0, 0, 0, 0.25) '}}>
            <div style={{color: "white", backgroundColor: "#4053af", padding: "10px 0px 10px 0px"}}>
                <span style={{marginLeft: "15px", fontSize: '1.25em'}}>
                    Shared with
                </span>
            </div>
            <div style={{marginLeft: "15px", marginTop: "10px"}}>
                For the protection of your data, share your secret with the smallest groups possible.
            </div>
            <Box style={{marginTop: "15px", marginLeft: "15px"}} sx={{'& .MuiTextField-root': { m: 0, width: '10ch' }}}>
                <table width="95%" style={{borderCollapse: "collapse"}}>
                    <thead>
                        <tr style={{borderBottom: "1px solid black", color: '#999', fontSize: '0.8rem', fontFamily: 'sans-serif'}}>
                            <th style={{textAlign: 'left', fontWeight: 100}}>Entity</th>
                            <th style={{textAlign: 'left', fontWeight: 100}}>Type</th>
                            <th style={{fontWeight: 100}}>Read</th>
                            <th style={{fontWeight: 100}}>Write</th>
                            <th style={{fontWeight: 100}}>Share</th>
                            <th>{/* DELETE */}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {shareData.map((permission, i) => (
                            <tr key={i} data-index={i}>
                                <td width="30%">
                                    { permissions.share && (
                                    <Autocomplete
                                        value={permission.entity}
                                        disableClearable
                                        freeSolo
                                        onChange={(event, newValue) => setEntityValue(i, newValue)}
                                        onInputChange={(event, newValue) => setEntityValue(i, newValue)}
                                        options={shareData[i]["type"] == "user" ? users : groups}
                                        renderInput={(params) => <TextField {...params} name="entity[]" variant="standard" style={{width: "100%"}} required />}
                                    />
                                    ) || 
                                        <TextField name="entity[]" variant="standard" style={{width: "100%"}} value={permission.entity || ''} required disabled />
                                    }
                                    {/* <TextField label="Entity" name="entity[]" variant="standard" value={permission.entity} onChange={handleShareChangeValue} required style={{width: "100%"}} /> */}

                                </td>
                                <td width="30%">
                                    <Select label="Type" labelId="select-type" onChange={handleShareChangeType} name={"type-"+i} variant="standard" value={permission.type} disabled={!permissions.share} required style={{width: "100%"}}>
                                        <MenuItem value="user">User</MenuItem>
                                        <MenuItem value="group">Group</MenuItem>
                                    </Select>
                                </td>
                                <td width="10%" align="center"><Checkbox name="read[]" checked={permission.read} style={{color: 'rgb(244 101 150)'}} onChange={handleShareChangeCheckbox} disabled={!permissions.share || permission.readBlock} /></td>
                                <td width="10%" align="center"><Checkbox name="write[]" checked={permission.write} style={{color: 'rgb(244 101 150)'}} onChange={handleShareChangeCheckbox} disabled={!permissions.share || permission.writeBlock} /></td>
                                <td width="10%" align="center"><Checkbox name="share[]" checked={permission.share} style={{color: 'rgb(244 101 150)'}} onChange={handleShareChangeCheckbox} disabled={!permissions.share || permission.shareBlock} /></td>
                                <td width="10%">
                                    {permissions.share && 
                                    <Tooltip title="Delete shared information" placement="top">
                                        <IconButton onClick={handleDeleteShare}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>                            
                </table>
                {permissions.share && <Button variant="text" onClick={() => setShareData([...shareData, {...permissionDict}])}><b>Add Entity</b></Button> }
            </Box>
        </div>
    );
}