import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import MUIDataTable from "mui-datatables";
import api from '../../services/api';
import { useParams } from 'react-router';

export default function AccessLogs(props) {
    const { id } = useParams();

    const [data, setData] = useState([]);
    const [count, setCount] = useState(-1);
    const [page, setPage] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');

    const columns = [
        {
            name: "Who", 
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "When", 
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "Change Id", 
            options: {
                filter: true,
                sort: false
            }
        }
    ];

    useEffect(() => {
        setLoading(true);
        api
            .get(`/accessLogs/${id}`, {
                params: {
                    page: page,
                    searchText: searchText
                }
            })
            .then((response) => { 
                const responseData = response.data.data.map(x => [x.WHO, new Date(x.WHEN).toLocaleString(), x.CHANGE_ID]);
                const newData = Array(10*page).fill([]).concat(responseData);
                setData(newData);
                setCount(response.data.total);
                setLoading(false);
            })
            .catch((err) => console.log("ERRO: " + err))        
    }, [page, searchText]);

    const options = {
        filterType: 'textField',
        selectableRows: 'none',
        serverSize: true,
        search: false,
        download: false, 
        print: false,
        viewColumns: false,
        filter: false,
        count,
        page,
        rowsPerPage: 10,
        rowsPerPageOptions: [10],
        customSearch: () => { return true }, // Prevent default search to filter only page items
        onTableChange: (action, tableState) => {
            if (action === "changePage" || action === "search") {
                setSearchText(tableState.searchText);
                setPage(tableState.page);           
            }
        }
    };
    
    return (
        <div className="AccessLogs">
            <MUIDataTable
            title={
                <Typography variant="h6">
                Access Logs
                {isLoading && (
                <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
                )                
                }
                </Typography>
            }          
            data={data}
            columns={columns}
            options={options}
            />
        </div>
    );
}