import ContentDrawer from "./components/ContentDrawer";
import { UserProvider } from "./contexts/user";
import { SnackbarProvider } from "notistack";

export default function App() {
    return (
        <SnackbarProvider maxSnack={3}>
            <UserProvider>
                <ContentDrawer/>
            </UserProvider>
        </SnackbarProvider>
    );
}