import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { CircularProgress, Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';
import "./secrets_modal.css";
import api from '../../services/api';
import { useSnackbar } from 'notistack';
import { createTheme, ThemeProvider } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '5px',
    boxShadow: 24,
    p: 3,
};

export default function SecretModal(props) {
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [visibility, setVisibility] = React.useState(false);
    const changeVisibility = () => setVisibility(!visibility);
    const [showCopyAlert, setShowCopyAlert] = React.useState(false);
    const [secret, setSecret] = React.useState();
    const handleCloseAlert = () => {
        if ("" === 'clickaway') {
            return;
        }

        setShowCopyAlert(false);
    };

    const showCopySnackbar = () => {
        enqueueSnackbar('Password successfuly copied!', { variant: 'success' } );
    }

    useEffect(() => {
        if(open) {            
            api.get(`/secretValue/${props.id}`, {
                params: {
                    changeId: props.changeId
                }
            }).then((res) => setSecret(res.data.VALUE));
        }
    },[open, props.id, props.changeId]);

    const getMuiTheme = () => createTheme({
        components: {
            MuiBackdrop: {
                styleOverrides:{
                    root: {
                        backgroundColor: 'rgb(0, 0, 0, 0.3)',
                        // backdropFilter: 'blur(5px)' // High CPU Usage
                    }
                }
            }
        }
    });

    return (
        <div className="SecretModal">
            {/* <Button onClick={() => console.log(props)}>Debug</Button>*/}
            <Tooltip title="View secret" placement="top">
                <IconButton onClick={handleOpen}>
                    <LockOpenIcon/>
                </IconButton>
            </Tooltip>
            <ThemeProvider theme={getMuiTheme()}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            {props.name}
                        </Typography>
                        <hr style={{marginBottom: "30px"}}/>
                        <Typography id="modal-modal-description" component={'span'} sx={{ mt: 2 }}>
                            {secret ? <>
                                {props.changeId && <div><b>Change:</b> {props.changeId}</div>}
                                <div>{visibility ? secret : "*****"}</div>                        
                                <div className="SecretModal-icons">
                                    {visibility ? 
                                        <Tooltip title="Hide secret" placement="top"><IconButton onClick={() => changeVisibility()}><VisibilityIcon/></IconButton></Tooltip> : 
                                        <Tooltip title="Show secret" placement="top"><IconButton onClick={() => changeVisibility()}><VisibilityOffIcon/></IconButton></Tooltip>
                                    }
                                    <Tooltip title="Copy secret to clipboard" placement="top">
                                        <IconButton onClick={() => { navigator.clipboard.writeText(secret); showCopySnackbar() } }>
                                            <ContentCopyIcon/> 
                                        </IconButton>                       
                                    </Tooltip>
                                </div>
                            </> : (<><CircularProgress size={24}/> Loading secret...</>)
                            }
                        </Typography>                    
                    </Box>
                </Modal>
            </ThemeProvider>
        </div>
    );
  }