import * as React from 'react';
import { useState } from "react";
import { TextField, Typography } from "@mui/material";
import { Button } from "@mui/material";
import SecretSharedWith from './sharedwith';
import api from '../../services/api';
import { useParams } from "react-router";
import { useSnackbar } from 'notistack';
import DeleteSecretModal from './deletesecretmodal';
import { CircularProgress, Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Tools from '../../services/tools';
import './secretform.css';

export default function EditSecret(props) {
    const { id }  = useParams();

    const {secretData, setSecretData, shareData, setShareData, permissionDict, permissions} = props;
    const {secretMetadata} = props;

    const [errors, setErrors] = useState({});
    const [errorsMsg, setErrorsMsg] = useState({});
    const [isValidForm, setValidForm] = useState(true);

    const { enqueueSnackbar } = useSnackbar();

    const [visibility, setVisibility] = React.useState(false);
    const changeVisibility = () => setVisibility(!visibility);
    const showCopySnackbar = () => {
        enqueueSnackbar('Password successfuly copied!', { variant: 'success' } );
    }

    const [multiLineMode, setMultiLineMode] = useState(false);
    const changeMultiLineMode = () => setMultiLineMode(!multiLineMode);
    const genPassword = () => {
        secretData.secret = Tools.generateRandomString();
        setSecretData({...secretData});
    }

    const handleSecretValueChange = (e) => {
        console.log(e.target.value);
        let name = e.target.name;
        if(name !== 'entity') {
            secretData[name] = e.target.value;
        }
        setSecretData({...secretData});
    };    

    const formValidate = (e) => {
        let validForm = true;

        /*if(secretData.description === '') {
            if(e.target.name === 'description') { // only show on blur
                errors.description = true;
                errorsMsg.description = 'Description is required';
            }
            validForm = false;
        } else {
            errors.description = false;
        }*/

        if(secretData.secret === '') {
            if(e.target.name === 'secret') { // only show on blur
                errors.secret = true;
                errorsMsg.secret = 'Secret is required';
            }
            validForm = false;
        }else {
            errors.secret = false;
        }

        /*if(secretData.rotation === '') {
            if(e.target.name === 'rotation') { // only show on blur
                errors.rotation = true;
                errorsMsg.rotation = 'Rotation is required';
            }
            validForm = false;
        }else {
            errors.rotation = false;
        }*/

        setErrors({...errors});
        setErrorsMsg({...errorsMsg});
        setValidForm(validForm);
        return validForm;
    }    

    const handleSubmit = (e) => {
        if(formValidate()) {
            api
                .patch(`/secret/${id}`, {
                    params: {
                        secretData: secretData,
                        shareData: shareData
                    }
                })
                .then((res) => {
                    enqueueSnackbar('Data saved', { variant: 'success' });
                    setTimeout(() => {
                        window.location.href = "/"
                    }, 1000);
                    
                })
                .catch((err) => {
                    enqueueSnackbar('Failed to submit', { variant: 'error' });              
                    console.log("ERROR ON SUBMIT: " + err)
                });
        } else {
            enqueueSnackbar('Failed to save, validations errors', { variant: 'error' });   
        }
    }

    return (
        <div className="EditSecret">
            <div style={{display: 'flex', padding: '20px'}}>
                <div style={{position: 'relative', backgroundColor: '#fafafa', width: '400px', padding: '10px'}}>
                    <Typography variant="caption" style={{color: '#727272'}}>Creator</Typography>
                    <Typography variant="body1">{secretMetadata.createdBy}</Typography>
                    <p></p>
                    <Typography variant="caption" style={{color: '#727272'}}>Created</Typography>
                    <Typography variant="body1">{secretMetadata.createdDate.replace('.000','').replace('T',' ').replace('Z', ' (UTC)')}</Typography>
                    <p></p>
                    <Typography variant="caption" style={{color: '#727272'}}>Last changed</Typography>
                    <Typography variant="body1">{secretMetadata.lastUpdate.replace('.000','').replace('T',' ').replace('Z', ' (UTC)')}</Typography>
                    <p></p>
                    <Typography variant="caption" style={{color: '#727272'}}>Last accesses</Typography>
                    {secretMetadata.lastAccesses.map((access, index) => (
                        <Typography variant="body1" key={index}>{access.replace('.000','').replace('T',' ').replace('Z', ' (UTC)')}</Typography>
                    ))}
                    <div style={{position: 'absolute', bottom: '0px'}}>
                        <DeleteSecretModal id={id}/>
                    </div>
                </div>
                <div style={{width: '100%', marginLeft: '30px'}}>
                    <div>
                        <TextField label="Name" name="name" variant="standard" value={secretData.name||''} disabled style={{width: "90%"}}/>
                    </div>
                    <div className={"secretspacer"}>
                        <TextField error={errors.description} helperText={errorsMsg.description} onBlur={formValidate} label="Description" name="description" variant="standard" value={secretData.description||''} onChange={handleSecretValueChange} required disabled={!permissions.share} style={{width: "90%"}} />
                    </div>
                    <div className={"secretspacer"}>
                        {!multiLineMode && 
                            <TextField error={errors.secret} helperText={errorsMsg.secret} onBlur={formValidate} label="Secret value" name="secret" variant="standard" type={visibility ? 'text' : 'password'} value={secretData.secret||''} autoComplete="on" onChange={handleSecretValueChange} required disabled={!permissions.share} style={{width: "40%"}} />
                        }                
                        <div style={{paddingTop: '10px', display: 'inline-block'}}>
                        {!multiLineMode && (visibility ? 
                            <Tooltip title="Hide secret" placement="top"><IconButton onClick={() => changeVisibility()}><VisibilityIcon/></IconButton></Tooltip> : 
                            <Tooltip title="Show secret" placement="top"><IconButton onClick={() => changeVisibility()}><VisibilityOffIcon/></IconButton></Tooltip>
                        )}
                        <Tooltip title="Copy secret to clipboard" placement="top">
                            <IconButton onClick={() => { navigator.clipboard.writeText(secretData.secret); showCopySnackbar() } }>
                                <ContentCopyIcon/> 
                            </IconButton>                       
                        </Tooltip>
                        <Tooltip title="Generate password" placement="top">
                            <IconButton onClick={() => genPassword()}>
                                <AutorenewIcon/> 
                            </IconButton>                       
                        </Tooltip>
                        <Tooltip title="Multi line mode" placement="top">
                            <IconButton onClick={() => changeMultiLineMode()} style={{background: multiLineMode && 'lightgray'}}>
                                <MultipleStopIcon/> 
                            </IconButton>                       
                        </Tooltip>
                        </div>
                        <TextField error={errors.rotation} helperText={errorsMsg.rotation} onBlur={formValidate} label="Rotation (in days)" name="rotation" variant="standard" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} value={secretData.rotation||''} onChange={handleSecretValueChange} disabled={!permissions.share} style={{width: "20%", float: "right", marginRight: "10%"}} />
                    </div>
                    {multiLineMode && 
                        <div className={"secretspacer"}>
                            <TextField error={errors.secret} helperText={errorsMsg.secret} onBlur={formValidate} label="Secret value" name="secret" value={secretData.secret||''} onChange={handleSecretValueChange} required disabled={!permissions.share} multiline rows={3} style={{width: '90%'}}/>
                        </div>
                    }
                    <div className={"secretspacer"} >
                        <SecretSharedWith 
                            edit={true}
                            shareData={shareData} 
                            setShareData={setShareData}
                            permissions={permissions}
                            permissionDict={permissionDict}
                        />
                    </div>                    
                </div>
            </div>
            <hr/>
            {permissions.write && 
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>                        
                        <Button variant="text" onClick={() => window.location.href = "/"}><b>&lt; BACK</b></Button>
                    </div>
                    <div>
                        <Button variant="text" onClick={handleSubmit} disabled={!isValidForm}><b>Save Changes</b></Button>                             
                    </div>
                </div>
            }
        </div>
    );
}