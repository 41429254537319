import { useState } from 'react';
import { Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import api from '../../services/api';

export default function FavoriteSecret(props) {
    const [favorited, setFavorited] = useState(props.favorited);
    const [isLoading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const applyFavorite = (secretId, favorited) => {
        setLoading(true);
        api
            .post(`/favorite/${secretId}`, {
                params: {
                    favorited: favorited
                }
            })
            .then((response) => {
                if(favorited) {
                    enqueueSnackbar('Secret favorited', { variant: 'success' } );
                } else {
                    enqueueSnackbar('Secret unfavorited', { variant: 'success' } );
                }
                setFavorited(favorited);             
            })
            .catch((err) => {
                enqueueSnackbar('Failed to change favorite status', { variant: 'error' });
                console.log("ERROR ON SUBMIT: " + err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
    <div>
        {isLoading ? (
            <CircularProgress
                size={30}
            />) : (
            <Tooltip title="Favorite" placement="top">
                <IconButton onClick={() => applyFavorite(props.secretId, !favorited)}>
                    { favorited ? <StarIcon/> : <StarBorderIcon/> }
                </IconButton>
            </Tooltip>)
        }
        
    </div>
    )
}