import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Dialog } from '@mui/material';
import { Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogTitle } from '@mui/material';
import { DialogContent } from '@mui/material';
import { DialogContentText } from '@mui/material';
import { DialogActions } from '@mui/material';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import api from '../../services/api';


export default function DeleteSecretModal(props) {
    const { id } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        api
            .delete(`/secret/${id}`)
            .then((response) => {
                if(response.data.success){
                    enqueueSnackbar('Secret deleted', { variant: 'success' });
                    window.location.href = '/'
                } else {
                    enqueueSnackbar('Error while trying to delete secret: ' + response.data.message, { variant: 'error' });
                }
            })
            .catch((err) => {
                enqueueSnackbar('Unknown error while trying to delete secret', { variant: 'error' });
                console.log(err);
            });
        
        setOpen(false);
    };

    return (
        <div>
            <Tooltip title="Delete secret" placement="top">
                <IconButton onClick={handleClickOpen}>
                    <DeleteIcon style={{color: "#E42217"}}/>
                </IconButton>
            </Tooltip>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Confirm Delete Secret"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure about delete this secret? This cannot be undone !
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}