import axios from "axios";
import { config } from "../config/config";

const api = axios.create({
    baseURL: config.server.url,
    withCredentials: true,
    rejectUnauthorized: false // Self Signed Certificate
  });
  
api.interceptors.response.use((response) => response, (error) => {
  if(error.response.status === 401) {
    window.location.href = config.server.login_url;
  } else {
    return Promise.reject(error);
  }
})

export default api;