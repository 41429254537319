import React, { useEffect, useState, useContext } from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import MUIDataTable from "mui-datatables";
import api from '../../services/api';
import SecretModal from './secrets_modal';
import PageviewIcon from '@mui/icons-material/Pageview';
import { Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material';
import { UserContext } from '../../contexts/user';
import FavoriteSecret from './favorite_secret';
import { Link } from "@mui/material";

export default function SecretsTable(props) {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(-1);
    const [page, setPage] = useState(0);
    const [isLoading, setLoading] = useState(true);
    // const [searchText, setSearchText] = useState('');
    const [searchText, setSearchText] = useContext(UserContext);
    const [searchTextRes, setSearchTextRes] = useState('');

    const columns = [
        {
            name: "",
            options: {
                setCellProps: () => ({ style: { width: '75px' }}),
                filter: false,
                sort: false,
                empty: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <FavoriteSecret 
                            secretId={data[dataIndex][3]}
                            favorited={data[dataIndex][0] == 1}
                        />
                    )
                }
            }
        },
        {
            name: "Name", 
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const linkPath = `/viewsecret/${data[dataIndex][3]}`
                    return (
                        <Link href={linkPath} style={{textDecoration: 'none', color: '#000', fontWeight: 600}}>{data[dataIndex][1]}</Link>
                    )
                }
            }
        },
        {
            name: "Description", 
            options: {
                filter: true,
                sort: false
            }
        },
        /*{
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (                    
                    <Tooltip title="Edit secret" placement="top">
                        <IconButton onClick={() => window.location.href=`/viewsecret/${data[dataIndex][3]}`}>
                            <PageviewIcon/>
                        </IconButton>
                    </Tooltip>
                    );
                }
            }
        },*/
        {
            name: "",
            options: {
                setCellProps: () => ({ style: { width: '75px' }}),
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (                    
                    <SecretModal
                        name={data[dataIndex][1]}
                        description={data[dataIndex][2]}
                        id={data[dataIndex][3]}
                    />
                    );
                }
            }
        }        
    ];

    useEffect(() => {     
        setLoading(true);
        api
            .get(`/secrets`, {
                params: {
                    page: page,
                    searchText: searchText,
                    filter: props.filter
                }
            })
            .then((response) => { 
                const responseData = response.data.data.map(x => [x.FAVORITED, x.NAME, x.DESCRIPTION, x.SECRET_ID]);
                const newData = Array(10*page).fill([]).concat(responseData);
                setData(newData);
                setCount(response.data.total);
                setLoading(false);
                setSearchTextRes(response.data.searchText);
            })
            .catch((err) => console.log("ERRO: " + err))        
    }, [page, searchText]);

    const options = {
        filterType: 'textField',
        selectableRows: 'none',
        serverSize: true,
        download: false, 
        print: false,
        viewColumns: false,
        filter: false,
        search: false,
        count,
        page,
        rowsPerPage: 10,
        rowsPerPageOptions: [10],
        customSearch: () => { return true }, // Prevent default search to filter only page items
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            if (action === "changePage" || action === "search") {
                // console.log("Go to page", tableState.page);                
                setSearchText(tableState.searchText);
                setPage(tableState.page);                
            }
        }
    };

    const getMuiTheme = () => createTheme({
        components: {
            MUIDataTableHeadCell: {
                styleOverrides:{
                    root: {
                        fontWeight: 700,
                        color: '#989898',
                        padding: '0px'
                        // color: '#495057',
                        // backgroundColor: '#e9ecef'
                    }
                }
            },
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        padding: '0px'
                    }
                }
            },
            MUIDataTable: {
                styleOverrides:{
                    responsiveBase: {
                        margin: '10px 10px 10px 10px'
                    }
                }
            }
        }
    })

    let headerTitle = 'All Secrets';
    if(props.filter === 'favorites') headerTitle = 'All Favorited Secrets';
    if(props.filter === 'usercreated') headerTitle = 'All Secrets Created By Me';
    
    return (
        <div className="SecretsTable">
            <ThemeProvider theme={getMuiTheme()}>
                <Typography variant="h6">
                    {searchTextRes == '' ? headerTitle : ['Search result for ',<b>{searchTextRes}</b>]}
                    {isLoading && (
                    <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />                    
                    )
                    }
                    <hr/>
                </Typography>
                <MUIDataTable
                    data={data}
                    columns={columns}
                    options={options}
                    />
            </ThemeProvider>
        </div>
    );
}