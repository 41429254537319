import React, { useState } from 'react';

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [searchText, setSearchText] = useState('');

  return (
    <UserContext.Provider value={[searchText, setSearchText]}>
    	{ children }
    </UserContext.Provider>
  )
}