import * as React from 'react';
import { useState } from "react";
import { Box } from "@mui/material"
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import SecretSharedWith from './sharedwith';
import Typography from '@mui/material/Typography';
import api from '../../services/api';
import { useSnackbar } from 'notistack';
import { CircularProgress, Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Tools from '../../services/tools';
import './secretform.css';

export default function AddSecret(props) {
    const permissionDict = {entity: '', type: 'user', read: true, write: true, share: true}

    const [secretData, setSecretData] = useState({name: '', description: '', secret: '', rotation: ''});
    const [shareData, setShareData] = useState([{...permissionDict}]);
    const [permissions, setPermissions] = useState({read: true, write: true, share: true});

    const [errors, setErrors] = useState({});
    const [errorsMsg, setErrorsMsg] = useState({});
    const [isValidForm, setValidForm] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const [visibility, setVisibility] = React.useState(false);
    const changeVisibility = () => setVisibility(!visibility);
    const showCopySnackbar = () => {
        enqueueSnackbar('Password successfuly copied!', { variant: 'success' } );
    }

    const [multiLineMode, setMultiLineMode] = useState(false);
    const changeMultiLineMode = () => setMultiLineMode(!multiLineMode);
    const genPassword = () => {
        secretData.secret = Tools.generateRandomString();
        setSecretData({...secretData});
    }
    
    const handleSecretValueChange = (e, teste) => {
        let name = e.target.name;
        
        if(name==='rotation') {
            if(!e.target.value.match(/^[0-9]{0,3}$/)) {
                e.target.value = secretData[name];
            }
        }

        secretData[name] = e.target.value;        
        setSecretData({...secretData});
    };

    const formValidate = (e) => {
        let validForm = true;
        if(secretData.name === '') {
            if(e.target.name === 'name') { // only show on blur
                errors.name = true;
                errorsMsg.name = 'Name is required';
            }            
            validForm = false;
        } else {
            errors.name = false;
        }

        /*if(secretData.description === '') {
            if(e.target.name === 'description') { // only show on blur
                errors.description = true;
                errorsMsg.description = 'Description is required';
            }
            validForm = false;
        } else {
            errors.description = false;
        }*/

        if(secretData.secret === '') {
            if(e.target.name === 'secret') { // only show on blur
                errors.secret = true;
                errorsMsg.secret = 'Secret is required';
            }
            validForm = false;
        }else {
            errors.secret = false;
        }

       /* if(secretData.rotation === '') {
            if(e.target.name === 'rotation') { // only show on blur
                errors.rotation = true;
                errorsMsg.rotation = 'Rotation is required';
            }
            validForm = false;
        }else {
            errors.rotation = false;
        }*/

        setErrors({...errors});
        setErrorsMsg({...errorsMsg});
        setValidForm(validForm);
        return validForm;
    }

    const handleSubmit = (e) => {
        if(formValidate()) {
            api
                .post('/secret', {
                    params: {
                        secretData: secretData,
                        shareData: shareData
                    }
                })
                .then((response) => {
                    const secretId = response.data.insertId;
                    setTimeout(() => {
                        window.location.href = `/viewsecret/${secretId}`;
                    }, 1000);
                })
                .catch((err) => {
                    enqueueSnackbar('Failed to save new secret', { variant: 'error' });
                    console.log("ERROR ON SUBMIT: " + err);
                })
        } else {
            enqueueSnackbar('Failed to submit, validations errors', { variant: 'error' });   
        }
    }

    return (
        <div className="AddSecret">            
            <Typography variant="h6">Create a secret</Typography>
            <hr/>            
            <Box                
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
                bgcolor="#FFF"
                style={{marginTop: "25px", width: 1000, border: '1px solid #ededed', borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 0px'}}
            >       
                <div style={{padding: "20px"}}>         
                    <div>
                        <TextField error={errors.name} helperText={errorsMsg.name} onBlur={formValidate} label="Name" name="name" variant="standard" onChange={handleSecretValueChange} required style={{width: "90%"}}/>
                    </div>
                    <div className={"secretspacer"}>
                        <TextField error={errors.description} helperText={errorsMsg.description} onBlur={formValidate} label="Description" name="description" variant="standard" onChange={handleSecretValueChange} style={{width: "90%"}} />
                    </div>
                    <div className={"secretspacer"}>
                    {!multiLineMode && 
                        <TextField error={errors.secret} helperText={errorsMsg.secret} onBlur={formValidate} label="Secret value" name="secret" variant="standard" type={visibility ? 'text' : 'password'} value={secretData.secret||''} autoComplete="on" onChange={handleSecretValueChange} required disabled={!permissions.share} style={{width: "40%"}} />
                    } 
                    <div style={{paddingTop: '10px', display: 'inline-block'}}>
                        {!multiLineMode && (visibility ? 
                            <Tooltip title="Hide secret" placement="top"><IconButton onClick={() => changeVisibility()}><VisibilityIcon/></IconButton></Tooltip> : 
                            <Tooltip title="Show secret" placement="top"><IconButton onClick={() => changeVisibility()}><VisibilityOffIcon/></IconButton></Tooltip>
                        )}
                        <Tooltip title="Copy secret to clipboard" placement="top">
                            <IconButton onClick={() => { navigator.clipboard.writeText(secretData.secret); showCopySnackbar() } }>
                                <ContentCopyIcon/> 
                            </IconButton>                       
                        </Tooltip>
                        <Tooltip title="Generate password" placement="top">
                            <IconButton onClick={() => genPassword()}>
                                <AutorenewIcon/> 
                            </IconButton>                       
                        </Tooltip>
                        <Tooltip title="Multi line mode" placement="top">
                            <IconButton onClick={() => changeMultiLineMode()} style={{background: multiLineMode && 'lightgray'}}>
                                <MultipleStopIcon/> 
                            </IconButton>                       
                        </Tooltip>
                        </div>
                        <TextField error={errors.rotation} helperText={errorsMsg.rotation} onBlur={formValidate} label="Rotation (in days)" name="rotation" variant="standard" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onChange={handleSecretValueChange} style={{width: "20%", float: "right", marginRight: "10%"}} />
                    </div>
                    {multiLineMode && 
                        <div className={"secretspacer"}>
                            <TextField error={errors.secret} helperText={errorsMsg.secret} onBlur={formValidate} label="Secret value" name="secret" value={secretData.secret||''} onChange={handleSecretValueChange} required disabled={!permissions.share} multiline rows={3} style={{width: '90%'}}/>
                        </div>
                    }
                    <div className={"secretspacer"}>
                        <SecretSharedWith 
                            shareData={shareData} 
                            setShareData={setShareData}
                            permissionDict={permissionDict}
                            permissions={permissions}
                        />
                    </div>   
                </div>
                <hr/>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>                        
                        <Button variant="text" onClick={() => window.location.href = "/"}><b>&lt; BACK</b></Button>
                    </div>
                    <div>
                        <Button variant="text" onClick={handleSubmit} disabled={!isValidForm}><b>Add Secret</b></Button>                             
                    </div>
                </div>
            </Box>
        </div>
    );
}