import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './home/home';
import AddSecret from './secrets/addsecret';
import LoginOkta from './login/login_okta';
import ViewSecret from './secrets/viewsecret';

function RoutedApp() {
  return (
    <div className='RoutedApp'>
      <Router>        
          <Routes>
            <Route path='/' caseSensitive={false} element={<Home filter={'all'}/>} />
            <Route path='/favorites' caseSensitive={false} element={<Home filter={'favorites'}/>} />
            <Route path='/usercreated' caseSensitive={false} element={<Home filter={'usercreated'}/>} />
            <Route path='/addsecret' caseSensitive={false} element={<AddSecret />} />
            <Route path='/viewsecret/:id' caseSensitive={false} element={<ViewSecret />} />
          </Routes>
      </Router>
    </div>
  );
}

export default RoutedApp;
