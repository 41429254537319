import React, { useEffect, useState } from "react";
import api from '../../services/api';
import { config } from "../../config/config";

export default function LoginOkta() {
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState("");    

    useEffect(() => {   
      api
        .get("/me", {
          withCredentials: true,
        })
        .then((res) => {  
          if (res.data.user.nameID) {
            setEmail(res.data.user.nameID);
            setLoading(false);
            sessionStorage.setItem("loginSession", JSON.stringify({lastCheck: new Date().getTime(), ...res.data.user}));
            window.location.replace(config.client.url);
          } else {
            window.location.replace(`${config.server.url}/login`);
          }
        })
        .catch((err) => {
          if(err.response.status == 401) {
            window.location.replace(`${config.server.url}/login`);
          }
        });
    }, []);

    return (
        <div className="LoginOkta">
            {loading && "Loading..."}
            {!loading && `Autenthicated as ${email}`}
        </div>
    );
}