import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import MUIDataTable from "mui-datatables";
import api from '../../services/api';
import SecretModal from '../secrets_table/secrets_modal';
import { useParams } from 'react-router';
import ChangeLogsModal from './changelogsmodal';

export default function ChangeLogs(props) {
    const { id } = useParams();
    const { secretData } = props;

    const [data, setData] = useState([]);
    const [count, setCount] = useState(-1);
    const [page, setPage] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');

    const columns = [
        {
            name: "Change Id", 
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "Who", 
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "When", 
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "Secret",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (                    
                    <SecretModal
                        id={id}
                        name={secretData.name}
                        description={secretData.description}                        
                        changeId={data[dataIndex][0]}
                        when={data[dataIndex][2]}
                    />
                    );
                }
            }
        },
        {
            name: "Changes",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (                    
                        <ChangeLogsModal
                            changes={data[dataIndex][3]}
                        />
                    );
                }
            }
        }
    ];

    useEffect(() => {
        setLoading(true);
        api
            .get(`/changeLogs/${id}`, {
                params: {
                    page: page,
                    searchText: searchText
                }
            })
            .then((response) => { 
                const responseData = response.data.data.map(x => [x.CHANGE_ID, x.WHO, new Date(x.WHEN).toLocaleString(), x.CHANGES]);
                const newData = Array(10*page).fill([]).concat(responseData);
                setData(newData);
                setCount(response.data.total);
                setLoading(false);
            })
            .catch((err) => console.log("ERRO: " + err))        
    }, [page, searchText]);

    const options = {
        filterType: 'textField',
        selectableRows: 'none',
        serverSize: true,
        search: false,
        download: false, 
        print: false,
        viewColumns: false,
        filter: false,
        count,
        page,
        rowsPerPage: 10,
        rowsPerPageOptions: [10],
        customSearch: () => { return true }, // Prevent default search to filter only page items
        onTableChange: (action, tableState) => {
            if (action === "changePage" || action === "search") {
                setSearchText(tableState.searchText);
                setPage(tableState.page);           
            }
        }
    };
    
    return (
        <div className="ChangeLogs">
            <MUIDataTable
            title={
                <Typography variant="h6">
                Change Logs
                {isLoading && (
                <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
                )                
                }
                </Typography>
            }          
            data={data}
            columns={columns}
            options={options}
            />
        </div>
    );
}